import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VOTE_ICON } from "./icons";

export const votingHelpContent: React.FC = () => (
  <>
    <FontAwesomeIcon icon={VOTE_ICON} size="4x" className="mb-3" />
    <h3>Vote</h3>
    <br />

    <p>
      Here you can vote on your favorite photos for the contest to determine the
      winners in each category of the contest.
    </p>

    <p>
      You can award as many votes as you like in every category, but each photo
      can only receive one vote per user.
    </p>

    <p>
      The 3 highest-voted photos in each category and the photo with the most
      votes overall will be recognized and awarded prizes at the 2025 Employee
      Recognition Dinner (2/1/2025).
    </p>
  </>
);
