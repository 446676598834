import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { ThemeConfigContext } from "../context/ThemeConfigContext";
import { HELP_ICON, IMAGES_ICON, MY_ACTIVITY_ICON, SUBMIT_ICON } from "./icons";

export const ONBOARDING_SLIDES: React.FC[] = [
  () => {
    const { fullLogoLightUrl, fullLogoDarkUrl, organizationName } = useContext(ThemeConfigContext);

    let logoUrl: string;
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      logoUrl = fullLogoDarkUrl;
    } else {
      logoUrl = fullLogoLightUrl;
    }

    return (
      <>
        <img
          src={logoUrl}
          alt={`${organizationName} logo`}
          className="w-75 mb-5"
        />

        <p>Welcome to the {organizationName} Photo Contest!</p>

        <p>
          Click “Next” to learn how to use the web app. Don't worry, this'll only take a
          minute.
        </p>
      </>
    );
  },
  () => <>
    <FontAwesomeIcon icon={IMAGES_ICON} size="4x" className="mb-3" />
    <h3>Gallery</h3>
    <br />

    <p>
      Here you can view, like, and comment on all the photos that have been
      entered into the contest so far.
    </p>

    <p>
      The controls located at top right of the screen allow you to filter photos
      by their category or switch between a grid- or feed-style view.
    </p>

    <p>
      Photos will be pre-qualified for voting based on the amount of interaction
      they receive from users. Be sure to like and comment on your favorite photos.
    </p>
  </>,
  () => <>
    <FontAwesomeIcon icon={SUBMIT_ICON} size="4x" className="mb-3" />
    <h3>My Photos</h3>
    <br />

    <p>
      On this screen you can upload up to 5 of your photos in each of the 5
      categories to enter the contest. Each image requires a Name and Category
      to be selected. For project photos, project name, number, and client are also
      required. Photos will be reviewed by a moderator before being published in
      the Gallery, so expect a delay.
    </p>
  </>,
  () => <>
    <FontAwesomeIcon icon={MY_ACTIVITY_ICON} size="4x" className="mb-3" />
    <h3>Activity</h3>
    <br />

    <p>
      On the Activity screen you can view all photos that you've liked or
      commented on previously.
    </p>
  </>,
  () => <>
    <FontAwesomeIcon icon={HELP_ICON} size="4x" className="mb-3" />
    <h3>Questions</h3>
    <br />

    <p>
      Please{" "}
      <a href="https://helpdesk.mccormicktaylor.com/support/tickets/new">
        submit a Helpdesk ticket
      </a>{" "}
      if you have further questions or encounter any issues.
    </p>

    <p>
      Under the "Category" input, select "Marketing", and under the
      "Sub-Category" input, select "Photo Contest".
    </p>
  </>,
];
