import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { RoleType } from "../types";
import { useNavigate } from "react-router-dom";

/**
 * Redirects users to the login screen or gallery who aren't logged in or whose role is not on the list of permittedRoles.
 * @param permittedRoles RoleType[] List of roles permitted on this page
 */
export const useAuthorization = (...permittedRoles: RoleType[]) => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [authorized, setAuthorized] = useState<boolean>(false);

  useEffect(() => {
    if (currentUser === null) {
      navigate("/auth/login/microsoft", { replace: true });
      setAuthorized(false);
    } else if (permittedRoles.includes(currentUser.role.type) === false) {
      navigate("/", { replace: true });
      setAuthorized(false);
    }
  }, [currentUser, navigate, setAuthorized, permittedRoles]);

  return { authorized };
};
